<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">【冠以时代底色缅怀历史，争做行业翘楚领航未来】恒安康泰致敬建党一百周年</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-07-01 11:15:15</span><span>阅读：252</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="text-indent: 2em; line-height: 1.75em;">
            百年前，中国共产党在黑暗中诞生，在苦难中成长，在挫折中奋起，在奋斗中壮大，从一个只有50多名党员的组织，发展成为世界上最大的马克思主义执政党，让山河破碎的中国走向强盛，让备受屈辱的民族走近世界舞台中央。</p>
          <p style="text-indent: 2em; line-height: 1.75em;">1925年，毛泽东橘子洲头独立寒秋，发出“问苍茫大地，谁主沉浮”的豪迈宣言。</p>
          <p style="text-indent: 2em; line-height: 1.75em;">今天，走过百年风雨征程，习近平总书记信心满怀：“中国共产党立志于中华民族千秋伟业，百年恰是风华正茂！”</p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1625109249.jpg" title="1625109249.jpg"
              alt="1.jpg" width="656" height="428"></p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            以“中国梦”“四个自信”凝聚团结奋斗伟力，以“五位一体”坚持和完善中国特色社会主义事业布局，以“四个全面”协调推进战略布局，以“使市场在资源配置中起决定性作用和更好发挥政府作用”深化社会主义市场经济体制改革，以“国家治理体系和治理能力现代化”夯实长治久安制度根基，以“新发展阶段、新发展理念、新发展格局”在变局之中开启全面建设社会主义现代化国家新征程……
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            立时代之潮头、应时代之变化，习近平新时代中国特色社会主义思想——这一当代中国的马克思主义、21世纪的马克思主义，以全新的视野深化了对共产党执政规律、社会主义建设规律、人类社会发展规律的认识，推动党和国家事业取得历史性成就、发生历史性变革，指引中华民族大踏步迈向伟大复兴。
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">2021年6月25日，在党的百年华诞即将到来之际，习近平总书记主持中共中央政治局第三十一次集体学习。</p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            “我们党历经百年沧桑依然风华正茂，其奥秘就在于具有自我净化、自我完善、自我革新、自我提高的强大能力，自我革命精神是党的执政能力的强大支撑。”深邃的话语，穿越百年、激荡时空。<br></p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">能胜强敌者，先自胜者也。</p>
          <p style="text-indent: 2em; line-height: 1.75em;">站在新的历史起点上，如何应对面向未来的“赶考”？</p>
          <p style="text-indent: 2em; line-height: 1.75em;">“开新局于伟大的社会革命，强体魄于伟大的自我革命”——这，就是中国共产党人的回答！这也是中国共产党领导下恒安康泰的回答！
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            医疗行业的发展关乎国计民生，每一位个体获得平等的健康权是我们的奋斗目标。打破传统异地就医难、行程安排难、医疗资源封闭的弊端，站在时代前沿，端看社会发展，互联网医疗的发展已成为时代之声，大势所趋。以便捷、精准、细致的服务，打通地域界限，破除异地就医看病过程的所有繁琐障碍，让用户的健康有一份在京电子档案，让用户的就医行程有一队自己的陪护。
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            正值建党百年，恰逢祖国繁荣，恒安康泰医疗健康科技有限责任公司携全体员工向中国共产党致敬，向党领导下的中国人民致敬。恒安康泰必将做大众赴京就医的贴心服务者，贯穿互联网+医疗+人文关怀的理念，让恒安康泰的陪伴，是在用户最需要的时刻。
          </p>
          <p><br></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl10',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl10',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>